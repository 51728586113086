import { http, newResults, defined, notZeroId } from './request.js'

class TermRequest {
  constructor() {
    this.id = null
    this.name = null
    this.author = null
    this.term = null
  }
}

export const api = {
  /**
   * Создает пустой запрос на добавление слова.
   * @returns {TermRequest}
   */
  create: function() {
    return new TermRequest()
  },

  parse: function(data) {
    const termRequest = this.create()

    if (defined(data.uuid)) {
      termRequest.id = data.uuid
    }

    if (defined(data.title)) {
      termRequest.name = data.title
    }

    if (defined(data.user_uuid) && notZeroId(data.user_uuid)) {
      termRequest.author = {
        id: data.user_uuid,
        name: data.user_title,
      }
    }

    if (defined(data.term_uuid) && notZeroId(data.term_uuid)) {
      termRequest.term = {
        id: data.term_uuid,
        name: data.term_title,
      }
    }

    return termRequest
  },

  findBy: async function(filter) {
    const results = newResults()

    const params = []
    if (typeof filter !== 'undefined') {
      if (filter.page) {
        params['page'] = filter.page - 1
      }
    }
    params['sort_by'] = 'created_at'
    params['sort_desc'] = true

    const response = await http.get('/term-requests', params)

    if (response.term_requests) {
      response.term_requests.forEach(termRequestData => {
        const termRequest = this.parse(termRequestData)
        results.push(termRequest)
      })

      results.meta.pages = parseInt(response?.filter?.pager?.total_pages)
      results.meta.total = parseInt(response?.filter?.pager?.total_items)
    }

    return results
  },

  /**
   * Возвращает количество новых предложенных слов.
   * @returns {Promise<number>}
   */
  fetchNewTotal: async function() {
    let total = 0

    const params = []
    params['without_term_only'] = true
    const response = await http.get('/term-requests', params)
    if (response.term_requests) {
      total = parseInt(response?.filter?.pager?.total_items)
    }

    return total
  },

  save: async function(term) {
    const data = new FormData()

    if (defined(term.name)) {
      data.append('title', term.name)
    }

    const json = !term.id
      ? await http.post('/term-requests', data)
      : await http.post(`/term-requests/${term.id}`, data)

    if (json.errors) {
      throw json.errors
    }

    if (json.term) {
      return this.parse(json.term)
    }

    return term
  },

  /**
   * Удаляет запрос на удаление слова.
   */
  delete: async function(termRequest) {
    const json = await http.delete(`/term-requests/${termRequest.id}`)
    if (json.errors) {
      throw json.errors
    }
    termRequest.deleted = true
    return termRequest
  },
}
