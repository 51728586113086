<template>
  <q-btn>
    <img src="../../assets/icons/context-menu.svg" alt="" />
    <q-menu class="context-menu">
      <q-list>
        <q-item clickable v-close-popup @click="showAddTermDialog(termRequest)">
          Добавить слово
        </q-item>
        <q-item clickable v-close-popup @click="showDeleteTermRequestDialog(termRequest)">
          Удалить
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import { api as termsApi } from '../../api/terms.js'
import EditTermDialog from '../../dialogs/terms/EditTermDialog.vue'
import DeleteTermRequestDialog from '../../dialogs/termsRequests/DeleteTermRequestDialog.vue'

export default {
  name: 'TermRequestContextMenu',
  props: {
    termRequest: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  methods: {
    showAddTermDialog(termRequest) {
      const term = termsApi.create()
      term.name = termRequest.name
      term.request = termRequest
      this.$root
        .createDialog({
          component: EditTermDialog,
          componentProps: {
            term: term,
            hook: this.hook
          },
        })
        .onOk(term => {
          this.$root.showSuccessMessage(`Слово «${term.name}» добавлено.`)
        })
    },
    showDeleteTermRequestDialog(termRequest) {
      this.$root
        .createDialog({
          component: DeleteTermRequestDialog,
          componentProps: {
            termRequest: termRequest,
            hook: this.hook
          },
        })
        .onOk(termRequest => {
          this.$root.showSuccessMessage(`Запрос «${termRequest.name}» удалён.`)
        })
    },
  }
}
</script>
