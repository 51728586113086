<template>
  <div v-if="pages > 1" class="pagination">
    <q-pagination
      :model-value="page"
      :max="pages"
      :max-pages="maxPages"
      direction-links
      boundary-links
      :icon-first="'img:' + getIcon('first')"
      :icon-prev="'img:' + getIcon('prev')"
      :icon-next="'img:' + getIcon('next')"
      :icon-last="'img:' + getIcon('last')"
      :padding="'0 1px'"
      :to-fn="toFn ? toFn : toFnDefault"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
    toFn: {
      type: Function,
    }
  },
  methods: {
    getIcon(icon) {
      var images = require.context('../assets/icons/', false, /\.svg$/)
      return images(`./${icon}.svg`)
    },
    toFnDefault(page) {
      const url = new URL(window.location)

      if (page > 1) {
        url.searchParams.set('page', page)
      } else {
        url.searchParams.delete('page')
      }

      const newFilter = {}
      Array.from(url.searchParams.keys()).forEach(key => {
        newFilter[key] = url.searchParams.get(key)
      })

      return { query: newFilter }
    },
  },
  computed: {
    maxPages() {
      return window.innerWidth < 600 ? 5 : 9
    },
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  margin: 50px -30px 0px -30px;

  .q-btn {
    color: #000000 !important;
    font-weight: 400;
    font-size: 14px;
    height: 38px;
    min-width: 38px !important;

    &:hover {
      text-decoration: none;
    }

    &.bg-primary .q-btn__content {
      background: transparent;
      color: #FFFFFF !important;
    }

    &.bg-primary:hover .q-btn__content {
      background: transparent !important;
      color: #FFFFFF !important;
    }

    &:hover .q-btn__content {
      background: #4A98F3;
      color: #FFFFFF !important;
    }

    .q-btn__content {
      background: #FFFFFF;
    }

    img {
      height: 14px;
    }

    &[disabled] img {
      opacity: 0.4;
    }
  }

  .q-pagination {
    background: #f5f6fa;
  }
}
</style>
